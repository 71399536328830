<template>
	<div class="wrapper">
		<div class="form-group">
			<label>Meno lekára</label>
			<input
			    type="text"
			    class="form-control"
			    placeholder="zadajte meno lekára"
			    v-model="name"
			    @keyup="search()"
			/>
		</div>
		<div class="form-group">
			<label>Mesto</label>
			<Select2
			    v-model="city"
			    :options="cities"
			    @change="getDoctors()"
			/>
		</div>
		<div class="form-group">
			<label>Špecializácia</label>
			<Select2
			    v-model="specialization"
			    :options="specializations"
			    @change="getDoctors()"
			/>
		</div>
	</div>
</template>

<script>
import { debounce } from 'lodash'
import Select2 from 'v-select2-component'
import axios from 'axios'
import config from '../../config'

export default {
	components: { Select2 },
	data() {
		return {
			name: this.$session.get('name') ? this.$session.get('name') : '',
			city: this.$session.get('city') ? this.$session.get('city') : 0,
			cities: [{ id: 0, text: 'vyberte mesto' }],
			specialization: this.$session.get('specialization') ? this.$session.get('specialization') : 0,
			specializations: [{ id: 0, text: 'vyberte špecializáciu' }]
		}
	},
	mounted() {
		this.getSpecializations(),
			this.$root.$on('city', city => {
				this.city = city
			}),
			this.$root.$on('cities', cities => {
				this.cities = cities
			})
		if (this.name != '' || this.city != 0 || this.specialization != 0) {
			this.getDoctors()
		}
	},
	methods: {
		getSpecializations() {
			axios.post(config.API + '/specialtylist').then(response => {
				response.data.map(value => {
					this.specializations.push({
						id: value.id,
						text: value.name
					})
				})
			})
		},
		search: debounce(function() {
			this.getDoctors()
		}, 500),
		getDoctors() {
			if (this.name == '' && +this.city == 0 && +this.specialization == 0) {
				this.$root.$emit('doctors', [])
			}
			let data = {}
			if (this.name != '') {
				data = Object.assign(data, { search: this.name })
				this.$session.set('name', this.name)
			} else {
				this.$session.set('name', '')
			}
			if (+this.city > 0) {
				data = Object.assign(data, { city_id: +this.city })
				this.$session.set('city', +this.city)
			} else {
				this.$session.set('city', 0)
			}
			if (+this.specialization > 0) {
				data = Object.assign(data, { specialty_id: +this.specialization })
				this.$session.set('specialization', +this.specialization)
			} else {
				this.$session.set('specialization', 0)
			}
			axios.post(config.API + '/search/', data).then(response => {
				this.$root.$emit('doctors', response.data)
			})
		}
	}
}
</script>

<style lang="scss">
.wrapper {
	margin-top: 3em;
}
label {
	color: #000000;
}
.form-control {
	padding-left: 8px !important;
	padding-right: 8px !important;
	font-size: 16px !important;
	color: #333333 !important;
	&::placeholder {
		color: #333333 !important;
		opacity: 1;
	}

	&:-ms-input-placeholder {
		color: #333333 !important;
	}

	&::-ms-input-placeholder {
		color: #333333 !important;
	}
}
.select2-container {
	width: 100% !important;
}
.form-control,
.select2-selection--single {
	border-color: #cccccc !important;
	border-radius: 0 !important;
	box-shadow: 0 0 5px 0 #cccccc !important;
}
.form-control,
.select2-selection--single,
.select2-selection__arrow {
	height: 34px !important;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
	line-height: 32px !important;
}
</style>
