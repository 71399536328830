<template>
	<div
	    id="ecasenkaSearch"
	    class="container"
	>
		<div class="row">
			<div class="col-sm-4 col-md-3">
				<DoctorsFilter />
			</div>
			<div class="col-sm-8 col-md-9">
				<DoctorsList />
			</div>
		</div>
	</div>
</template>

<script>
import DoctorsFilter from '@/components/DoctorsFilter.vue'
import DoctorsList from '@/components/DoctorsList.vue'

export default {
	name: 'ecasenkaSearch',
	components: {
		DoctorsList,
		DoctorsFilter
	}
}
</script>

<style lang="scss" scoped>
#ecasenkaSearch {
	@import url('https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css');
	font-size: 16px;
	line-height: 1.2;
}
</style>
