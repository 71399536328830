<template>
	<div class="wrapper">
		<div v-if="doctors.length > 0">
			<a
			    :href="doctor.url"
			    v-for="(doctor, index) in doctors"
			    :key="index.id"
			>
				<div class="media">
					<div class="media-left media-middle hidden-xs">
						<figure>
							<img
							    :src="path + avatar"
							    alt="doctor"
							/>
						</figure>
					</div>
					<div class="media-body p-1">
						<p class="name">{{ doctor.name }}</p>
						<p class="address">{{ doctor.address }}</p>
						<p class="specialization">{{ doctor.specialty }}</p>
						<p class="drname">{{ doctor.drname }}</p>
						<p class="notice">{{ doctor.notice }}</p>
						<p class="working hidden-lg">{{ doctor.todayisworkingtext }}</p>
						<button class="btn btn-primary hidden-lg">Vstúpiť</button>
					</div>
					<div class="media-right p-1 hidden-xs hidden-sm hidden-md">
						<p
						    class="working"
						    :class="{'red':!doctor.todayisworking}"
						>{{ doctor.todayisworkingtext }}</p>
						<button class="btn btn-primary btn-block">Vstúpiť</button>
					</div>
				</div>
			</a>
		</div>
		<div
		    v-if="loading"
		    class="spinner text-center"
		>
			<img
			    class="img"
			    :src="path + spinner"
			    alt="doctor"
			/>
		</div>
		<div
		    v-if="doctors.length == 0 && !loading"
		    class="text-center"
		>
			<p class='warning'>Podľa zadaných filtrov sme nenašli žiadneho lekára v našej databáze. Skúste zmeniť kritéria meno, mesto alebo špecializáciu.</p>
		</div>
	</div>
</template>

<script>
import axios from 'axios'
import config from '../../config'
import spinner from '@/assets/squares.gif'
import avatar from '@/assets/doctor.svg'

export default {
	data() {
		return {
			spinner: spinner,
			avatar: avatar,
			path: config.URL,
			loading: false,
			doctors: []
		}
	},
	created() {
		this.loading = true
		if (!this.$session.exists()) {
			this.$getLocation()
				.then(coordinates => {
					axios.get(config.API + '/citylist').then(response => {
						let cities = [{ id: 0, text: 'vyberte mesto' }]
						response.data.map(value => {
							cities.push({
								id: value.id,
								text: value.name
							})
							this.$root.$emit('cities', cities)
						})
						let citylist = []
						response.data.map(value => {
							let distance = this.distance(coordinates.lat, coordinates.lng, +value.lat, +value.lon)
							citylist.push({
								id: value.id,
								dist: distance,
								city: value.name
							})
						})
						let minDistance = Math.min(...citylist.map(value => value.dist))
						citylist = citylist.filter(value => value.dist == minDistance)
						axios.post(config.API + '/search/', { city_id: citylist[0].id }).then(response => {
							this.doctors = response.data
							this.loading = false
							this.$root.$emit('city', citylist[0].id)
						})
					})
				})
				.catch(() => {
					this.loading = false
					axios.get(config.API + '/citylist').then(response => {
						let cities = [{ id: 0, text: 'vyberte mesto' }]
						response.data.map(value => {
							cities.push({
								id: value.id,
								text: value.name
							})
						})
						this.$root.$emit('cities', cities)
					})
				})
		} else {
			axios.get(config.API + '/citylist').then(response => {
				let cities = [{ id: 0, text: 'vyberte mesto' }]
				response.data.map(value => {
					cities.push({
						id: value.id,
						text: value.name
					})
				})
				this.$root.$emit('cities', cities)
			})
		}
	},
	mounted() {
		this.$root.$on('doctors', doctors => {
			this.loading = true
			this.doctors = doctors
			this.loading = false
		})
	},
	methods: {
		distance(lat1, lng1, lat2, lng2) {
			var radlat1 = (Math.PI * lat1) / 180
			var radlat2 = (Math.PI * lat2) / 180
			var theta = lng1 - lng2
			var radtheta = (Math.PI * theta) / 180
			var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta)
			dist = Math.acos(dist)
			dist = (dist * 180) / Math.PI
			dist = dist * 60 * 1.1515
			return dist
		}
	}
}
</script>

<style scoped lang="scss">
.wrapper {
	margin-top: 3em;
}
.btn-primary {
	background: -webkit-linear-gradient(132deg, #21366d 16%, #56b3de 100%);
	background: linear-gradient(132deg, #21366d 16%, #56b3de 100%);
	border: none;
	border-radius: 0;
	&:hover {
		background: -webkit-linear-gradient(132deg, #007cc5 12%, #56b3de 100%);
		background: linear-gradient(132deg, #007cc5 12%, #56b3de 100%);
	}
}
a:hover {
	text-decoration: none;
}
.media {
	margin-bottom: 2em;
	border: 1px solid #cccccc;
	box-shadow: 0 0 5px 0 #cccccc;
	.media-left {
		padding-right: 0;
		border-right: 1px solid #cccccc;
	}
	.media-right {
		border-left: 1px solid #cccccc;
		min-width: 200px;
	}
}
.p-1 {
	padding: 1em;
}
figure {
	width: 200px;
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}
.name {
	color: #000;
	font-size: 1.5em;
	font-weight: 700;
	line-height: 1.2;
}
.address {
	color: #000;
	font-size: 0.875em;
}
.specialization {
	margin-bottom: 0;
	color: #000;
	font-size: 1em;
	font-weight: 600;
}
.drname {
	color: #000;
	font-size: 0.875em;
}
.notice {
	margin-bottom: 0;
	color: #000;
	font-size: 0.875em;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}
.working {
	color: #00b925;
	font-weight: 600;
	&.red {
		color: #ff4747;
	}
}
.warning {
	padding-top: 50px;
	margin: 0 auto;
	max-width: 80%;
	font-size: 20px;
	font-weight: 500;
}
@media (max-width: 991px) {
	figure {
		width: 120px;
	}
}
</style>
